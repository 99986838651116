<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{action}} Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mstTagihanBank'}"
                    >
                        <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-form class="needs-validation" @submit.prevent="submitForm">
                    <b-form-row>
                        <b-col
                            md="10"
                            offset-md="1"
                        >
                            <!-- input kodeBank -->
                            <b-form-group
                                label="Kode Bank"
                                label-for="kodeBank"
                            >
                                <b-form-input
                                    id="kodeBank"
                                    v-model="v$.kodeBank.$model"
                                    :state="validateState(v$.kodeBank)"
                                    @keydown="changeIntOnly"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.kodeBank.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input bank -->
                            <b-form-group
                                label="Nama Bank"
                                label-for="bank"
                            >
                                <b-form-input
                                    id="bank"
                                    v-model="bank"
                                    :state="validateState(v$.bank)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.bank.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input nama -->
                            <b-form-group
                                label="Nama"
                                label-for="nama"
                            >
                                <b-form-input
                                    id="nama"
                                    v-model="nama"
                                    :state="validateState(v$.nama)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.nama.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input noRekening -->
                            <b-form-group
                                label="No. Rekening"
                                label-for="noRekening"
                            >
                                <b-form-input
                                    id="noRekening"
                                    v-model="noRekening"
                                    :state="validateState(v$.noRekening)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.noRekening.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-col
                                cols="12"
                                class="text-right pr-0"
                            >
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    class="mr-1"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                >
                                <div v-if="isSubmit">
                                    <b-spinner small />
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else>Simpan</div>
                                </b-button>
                            </b-col>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner
} from 'bootstrap-vue'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {setInputIntOnly} from '@/libs/helper'

export default {
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        //form name
        var bank = ref('')
        var nama = ref('')
        var noRekening = ref('')
        var kodeBank = ref('')

        watchEffect(() => {
            bank.value = props.bankVal
            nama.value = props.namaVal
            noRekening.value = props.noRekeningVal
            kodeBank.value = props.kodeBankVal
        })

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            bank: { 
                required: helpers.withMessage('Nama Bank '+requiredMsg, required)
            },
            nama: { 
                required: helpers.withMessage('Nama '+requiredMsg, required)
            },
            noRekening: { 
                required: helpers.withMessage('No. Rekening '+requiredMsg, required)
            },
            kodeBank: { 
                required: helpers.withMessage('Kode Bank '+requiredMsg, required)
            },
        }))

        const v$ = useVuelidate(rules, { bank, nama, noRekening, kodeBank }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            // console.log(router.push({name: 'mjmn-pengguna'}));
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                bank: bank.value,
                nama: nama.value,
                noRekening: noRekening.value,
                kodeBank: kodeBank.value,
                id: props.id
            };

            $http({
                url: '/mst/tagihan/bank/submit/'+props.action,
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'mstTagihanBank'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeIntOnly = (evt) => {
            setInputIntOnly(evt)
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const resetForm = () => {
            bank.value = ''
            nama.value = ''
            noRekening.value = ''
        }

        return {
            v$, $externalResults, isSubmit,
            bank, nama, noRekening, kodeBank,
            resetForm, validateState, submitForm, changeIntOnly
        }
    },
    directives: {
        Ripple,
    },
    components: {
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner
    },
    props: {
        action: String,
        namaVal: String,
        bankVal: String,
        noRekeningVal: String,
        kodeBankVal: String,
        id: String
    },
}
</script>

<style lang="scss" scope>
</style>